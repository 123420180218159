import { ROUTE_PATH } from './route-path';

const routes = [
  // Dont Remove. Handle 404 Pages
  {
    path: '*',
    component: 'pages/guest/NotFound',
    auth: false,
  },
  {
    path: '/403',
    component: 'pages/guest/Forbidden',
    auth: false,
  },
  {
    path: '/',
    component: 'pages/guest/Login',
    auth: false,
  },
  // temporary comment out routes which are not part of the MVP
  //   {
  //     path: ROUTE_PATH.notice,
  //     component: 'pages/authenticated/Dashboard',
  //     auth: true,
  //   },
  //   {
  //     path: ROUTE_PATH.listOfInquiries,
  //     component: 'pages/authenticated/Dashboard',
  //     auth: true,
  //   },
  //   {
  //     path: ROUTE_PATH.customization,
  //     component: 'pages/authenticated/Dashboard',
  //     auth: true,
  //   },
  {
    path: ROUTE_PATH.groupSettings,
    component: 'pages/authenticated/GroupSettings',
    auth: true,
    roles: ['Administrator'],
  },
  {
    path: ROUTE_PATH.goalSettings,
    component: 'pages/authenticated/GoalSettings',
    auth: true,
    roles: ['Administrator'],
  },
  //   {
  //     path: ROUTE_PATH.top,
  //     component: 'pages/authenticated/Dashboard',
  //     auth: true,
  //   },
  //   {
  //     path: ROUTE_PATH.schedule,
  //     component: 'pages/authenticated/Dashboard',
  //     auth: true,
  //   },
  {
    path: ROUTE_PATH.salesManagement,
    component: 'pages/authenticated/SalesManagement',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
  {
    path: ROUTE_PATH.orderManagement,
    component: 'pages/authenticated/Dashboard',
    auth: true,
  },
  // temporary comment out routes which are not part of the MVP
  //   {
  //     path: ROUTE_PATH.bulletinBoard,
  //     component: 'pages/authenticated/Dashboard',
  //     auth: true,
  //   },
  //   {
  //     path: ROUTE_PATH.todo,
  //     component: 'pages/authenticated/Dashboard',
  //     auth: true,
  //   },
  // temporary comment out routes which are not part of the MVP
  //   {
  //     path: ROUTE_PATH.help,
  //     component: 'pages/authenticated/Dashboard',
  //     auth: true,
  //   },
  {
    path: ROUTE_PATH.login,
    component: 'pages/guest/Login',
    auth: false,
  },
  {
    path: ROUTE_PATH.dashboard,
    component: 'pages/authenticated/Dashboard',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver', 'HokkaidoAdmin'],
  },
  {
    path: ROUTE_PATH.office,
    component: 'pages/authenticated/OfficeList',
    auth: true,
    roles: ['HokkaidoAdmin'],
  },
  {
    path: ROUTE_PATH.officeCreate,
    component: 'pages/authenticated/OfficeCreate',
    auth: true,
    roles: ['HokkaidoAdmin'],
  },
  {
    path: ROUTE_PATH.productTypeCreate,
    component: 'pages/authenticated/ProductType',
    auth: true,
    roles: ['Administrator'],
  },
  {
    path: ROUTE_PATH.productType,
    component: 'pages/authenticated/ProductTypeList',
    auth: true,
    roles: ['Administrator'],
  },
  {
    path: ROUTE_PATH.changePIC,
    component: 'pages/authenticated/ChangePIC',
    auth: true,
    roles: ['Administrator'],
  },
  {
    path: ROUTE_PATH.customerCreate,
    component: 'pages/authenticated/ClientCreate',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
  {
    path: ROUTE_PATH.customerEdit,
    component: 'pages/authenticated/ClientEdit',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
  {
    path: ROUTE_PATH.customerView,
    component: 'pages/authenticated/Client/ClientView',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
  {
    path: ROUTE_PATH.product,
    component: 'pages/authenticated/ProductList',
    auth: true,
    roles: ['Administrator'],
  },
  {
    path: ROUTE_PATH.productCreate,
    component: 'pages/authenticated/ProductCreate',
    auth: true,
    roles: ['Administrator'],
  },
  {
    path: ROUTE_PATH.productEdit,
    component: 'pages/authenticated/ProductEdit',
    auth: true,
    roles: ['Administrator'],
  },
  {
    path: ROUTE_PATH.supplierCreate,
    component: 'pages/authenticated/SupplierCreate',
    auth: true,
    roles: ['Administrator'],
  },
  {
    path: ROUTE_PATH.supplier,
    component: 'pages/authenticated/SupplierList',
    auth: true,
    roles: ['Administrator'],
  },
  {
    path: ROUTE_PATH.about,
    component: 'pages/guest/About',
    auth: false,
  },
  {
    path: ROUTE_PATH.signup,
    component: 'pages/guest/Signup',
    auth: false,
  },
  {
    path: ROUTE_PATH.forgotPassword,
    component: 'pages/guest/ForgotPassword',
    auth: false,
  },
  {
    path: ROUTE_PATH.resetPassword,
    component: 'pages/guest/ResetPassword',
    auth: false,
  },
  {
    path: ROUTE_PATH.activate,
    component: 'pages/guest/Activate',
    auth: false,
  },
  {
    path: ROUTE_PATH.profile,
    component: 'pages/authenticated/Profile',
    auth: true,
    roles: ['HokkaidoAdmin', 'Administrator', 'Employee', 'Approver'],
  },
  {
    path: ROUTE_PATH.terms,
    component: 'pages/guest/Terms',
    auth: false,
  },
  {
    path: ROUTE_PATH.users,
    component: 'pages/authenticated/Users',
    auth: true,
    roles: ['HokkaidoAdmin', 'Administrator'],
  },
  {
    path: ROUTE_PATH.integrations,
    component: 'pages/authenticated/Integrations',
    auth: true,
  },
  // temporary comment out routes which are not part of the MVP
  //   {
  //     path: ROUTE_PATH.faq,
  //     component: 'pages/guest/Faq',
  //     auth: true,
  //     roles: ['Employee', 'Approver', 'HokkaidoAdmin', 'Administrator'],
  //   },
  //   {
  //     path: ROUTE_PATH.inquiry,
  //     component: 'pages/guest/Inquiry',
  //     auth: false,
  //   },
  {
    path: ROUTE_PATH.privacyPolicy,
    component: 'pages/guest/PrivacyPolicy',
    auth: false,
  },
  // For administrator
  {
    path: ROUTE_PATH.environmentSettings,
    component: 'pages/authenticated/EnvironmentSettings',
    auth: true,
  },
  // For users with office account
  {
    path: ROUTE_PATH.environmentSettingsOffice,
    component: 'pages/authenticated/EnvironmentSettings',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver', 'HokkaidoAdmin'],
  },
  // Users management
  {
    path: ROUTE_PATH.addUsers,
    component: 'pages/authenticated/AddUsers',
    auth: true,
    roles: ['HokkaidoAdmin', 'Administrator'],
  },
  {
    path: ROUTE_PATH.updateUsers,
    component: 'pages/authenticated/UpdateUsers',
    auth: true,
    roles: ['HokkaidoAdmin', 'Administrator'],
  },
  {
    path: ROUTE_PATH.officeSettings,
    component: 'pages/authenticated/Office/OfficeSettings',
    auth: true,
    roles: ['Administrator'],
  },
  // Meeting management
  {
    path: ROUTE_PATH.addBusinessMeeting,
    component: 'pages/authenticated/BusinessMeetingCreate',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
  // Order management
  {
    path: ROUTE_PATH.viewOrder,
    component: 'pages/authenticated/Order/Estimate',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
  {
    path: ROUTE_PATH.createOrder,
    component: 'pages/authenticated/Order/EstimateCreate',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
  {
    path: ROUTE_PATH.approveEstimate,
    component: 'pages/authenticated/Order/Estimate',
    auth: true,
    roles: ['Administrator', 'Approver'],
  },
  {
    path: ROUTE_PATH.guestViewOrder,
    component: 'pages/guest/ApproveEstimate',
    auth: false,
  },
  // Deposit Management
  {
    path: ROUTE_PATH.depositManagement,
    component: 'pages/authenticated/Deposit/DepositManagement',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
  // Performance Management
  {
    path: ROUTE_PATH.performanceManagement,
    component: 'pages/authenticated/Performance/PerformanceManagement',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
  // Top View
  {
    path: ROUTE_PATH.topView,
    component: 'pages/authenticated/Top',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
  // Schedule View
  {
    path: ROUTE_PATH.calendarMonthView,
    component: 'pages/authenticated/Calendar/CalendarView',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
  // Meeting room management
  {
    path: ROUTE_PATH.meetingRoomList,
    component: 'pages/authenticated/MeetingRoom/MeetingRoomList',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
  // Meeting room create
  {
    path: ROUTE_PATH.meetingRoomCreate,
    component: 'pages/authenticated/MeetingRoom/MeetingRoomCreate',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
  // Meeting room edit
  {
    path: ROUTE_PATH.meetingRoomUpdate,
    component: 'pages/authenticated/MeetingRoom/MeetingRoomUpdate',
    auth: true,
    roles: ['Administrator', 'Employee', 'Approver'],
  },
];

// Don't include styleguide in production routes
if (process.env.ENVIRONMENT !== 'production') {
  routes.push({
    path: '/styleguide',
    component: 'pages/guest/Styleguide',
    auth: false,
  });
}

export default routes;
