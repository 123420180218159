const ja = {
  translation: {
    // define translations below
    form: {
      required: 'この項目は必須です。',
      required1: 'は必須項目です。',
      email: 'メール形式が無効です。',
      email_address1: 'メールアドレス',
      password: {
        minLength: 'パスワードは8文字以上である必要があります。',
        confirm: 'パスワードと確認用パスワードが一致しません。確認してください。',
        strong:
          'パスワードには半角英大文字・半角英小文字・数字をそれぞれ一つ以上含めた8文字以上で入力してください',
      },
      invalid: '認証が失敗しました。メールアドレスとパスワードが正しいかどうかを確認してください。',
      invalid_password: 'パスワードが間違っています',
      unverified_email: 'このメールアドレスは認証されていません。',
      unregistered_email_address: 'このメールアドレスは登録されていません。',
      user_locked: 'アカウントロック中です。しばらくしてから再度ログインしてください。',
      empty: 'を入力してください。',
      incorrect_format: 'の形式で入力して下さい。',
      new_password: '新パスワード​',
      success: '新規データが保存されました。',
      unique: 'この{{field}}はすでに使用されています',
      invalid_image_type: 'PNG、JPG、または JPEG（最大2MB）の形式で添付してください。',
      invalid_csv_file_size: 'CSV（最大10MB）の形式で添付してください。',
      status_update: {
        confirm_message: 'ステータスを変更してもよろしいですか。',
        success: 'ステータスが更新されました。',
        error: 'ステータスの更新に失敗しました。',
      },
      confirm: '確認',
      ok: 'OK',
      please_enter_a_hyphen: 'ハイフンを入力して下さい。 例: 100-1234',
    },
    confirm: {
      cancel: 'キャンセルしますか。',
      load_updated_group_duplicate:
        'グループ設定が更新されている可能性があります。この目標の複製を続行しますか?',
      load_updated_group_edit: 'グループ設定に更新があります。目標の編集を続行しますか?',
    },
    labels: {
      first_name: 'ファーストネーム',
      last_name: '苗字',
      login: 'ログイン',
      signup: 'サインアップ',
      remember_me: '私を覚えてますか',
      forgot_password: 'パスワードをお忘れの方はこちら',
      email_address: 'メールアドレス',
      password: 'パスワード',
      confirm_password: 'パスワードを認証する',
      submit: '送信',
      create: '作成する',
      update: 'アップデート',
      save: '保存する',
      add_new: '新規作成',
      reset_password: 'パスワードを再設定する',
      new_password: '新しいパスワード',
      confirm_new_password: '新しいパスワードを確認',
      delete: '削除',
      new_password_confirmation: '確認',
      enter_keyword: 'キーワードを入力してください',
      get_started: 'はじめましょう',
      integrations: '統合',
      settings: '設定',
      documentation: 'ドキュメンテーション',
      fullname: 'フルネーム',
      inquiry_content: 'お問合わせ内容',
      navigation: 'ナビゲーション',
      resources: 'リソース',
      cancel: 'キャンセル',
      close: '閉じる',
      action: 'アクション',
      showPassword: 'パスワードを表示',
      hidePassword: 'パスワードを隠す',
      password_change: '変更',
      environment_setting: '環境設定一覧',
      add_opportunity: '商談を追加',
      add_internal_meeting: '予定を追加',
      product_name: '商材名',
      pic_department: '部署',
      search: '検索する',
      number_pattern: 'M${yyyy}-${mm}-${number,4,1}',
      app_title: 'Ashirias Gate',
      initial: 'さん',
      csv_download: 'サンプルCSV',
      csv_upload: 'CSVインポート',
      dear: '御中',
    },
    pages: {
      signup: {
        agree_to_terms: '[サインアップ]をクリックすると、読んだことに同意したことになります',
        signup_complete:
          '確認メールが受信トレイに送信されました。 リンクをクリックして、登録プロセスを完了します。',
        terms_conditions: '規約と条件',
        create_free_account: '無料アカウントを作成する',
      },
      forgot_password: {
        sub_heading: 'アカウントを復旧するには、以下にメールアドレスを入力してください。',
        success: 'パスワードをリセットする方法については、受信トレイを確認してください。',
        label1: 'パスワードの変更を行うメールアドレスをご入力ください',
        label2: '入力されたメールアドレスにパスワード変更の案内を送信します。',
      },
      reset_password: {
        sub_heading: '新しいパスワードを入力してください。',
        success: 'パスワードは正常に更新されました。',
        password_changed: 'パスワードが変更されました',
      },
      users: {
        add_user: 'ユーザーを追加する',
        create_member: 'メンバー作成',
        update_member: 'メンバー編集',
        create_user: '新規作成',
        delete_confirmation: '選択したユーザを削除しますか。',
        delete_user: 'ユーザーを削除',
        display_name: '表示名',
        edit_user: 'ユーザー編集',
        edit: '編集',
        email_address: 'メールアドレス',
        first_name: 'ファーストネーム',
        invite_status: '招待',
        last_name: '苗字',
        manager: '管理者',
        phone_number: '電話',
        status: '状態',
        update: 'edit_user',
        username: '名前',
        user_created: 'ユーザーが作成されました。',
        user_group: 'グループ',
        user_role: 'ロール',
        user_updated: 'ユーザーの詳細が更新されました。',
        user_deleted: 'ユーザーが削除されました',
        file: {
          import: 'CSVインポート',
          export: 'CSVダウンロード',
        },
      },
      activate: {
        heading: 'アカウントをアクティブ化',
        subtitle: 'アカウントをアクティブ化するためのパスワードを設定します。',
        activated: 'アカウントが有効になりました。 これで、アカウントにログインできます。',
      },
      dashboard: {
        main_heading: 'React Base Templateへようこそ！',
        sub_heading: 'Reactプロジェクトの開発に関する軽量の定型文。',
        new_users: '新しいユーザー',
        total_sales: '総売上高',
        total_orders: '総注文数',
      },
      not_found: {
        title: 'ページが見つかりません',
        sub_heading: 'お探しのページは削除されたか、別の場所へ移動した可能性があります。',
        back_to_top: 'トップページへ戻る',
      },
      forbidden: {
        title: '403 Forbidden',
        sub_heading: 'このページにアクセスする権限がありません。',
      },
      faq: {
        heading: 'よくあるご質問',
        sub_heading: 'お客様からお問い合わせいただく質問をQ&A形式でまとめました。',
      },
      inquiry: {
        heading: 'お問い合わせ',
        sub_heading: 'このままお問い合わせされる方は下記のフォームにご入力ください。',
        success_message: 'お問い合わせを送信しました。',
        failed_message: '送信中にエラーが発生しました。',
      },
      profile: {
        heading: 'プロファイル編集',
        sub_heading: 'アカウント情報を更新します。',
        success_message: '詳細が正常に更新されました。',
        failed_message: '更新に失敗しました。',
      },
      landing: {
        main_heading: 'React Base Templateへようこそ！',
        sub_heading: 'Reactプロジェクトの開発に関する軽量の定型文。',
        why_heading: 'なぜベーステンプレートを使用するのですか？',
        docker: {
          heading: '柔軟な環境',
          description:
            '「自分のマシンで動作する」という問題を完全に解消します。 環境のセットアップ、環境固有の問題のデバッグ、およびより移植性が高くセットアップが簡単なコードベースに費やす時間を短縮します。',
        },
        react: {
          heading: '高速で直感的なUI',
          description:
            'ReactJSは非常に直感的に操作でき、UIのレイアウトに双方向性を提供します。 これらのコンポーネントを利用して1つの場所に統合できるように、構成可能です。 したがって、コードははるかに保守可能で柔軟になります。',
        },
        laravel: {
          heading: '強力なAPI',
          description:
            'LaravelのAPI機能を利用してバックエンドAPIを簡単に開発できます。 サードパーティの統合とライブラリが簡単で、すばやく簡単です。',
        },
        our_s_heading: 'お客様',
        reviews_heading: '私たちのクライアントが言うこと',
        see_all_reviews: 'すべてのレビューを見る',
        call_to_action: '今すぐアプリケーション開発を加速しましょう!',
      },
      about: {
        main_heading: '私たちの物語',
        sub_heading:
          '私たちは、私たちが信じる人々のために誇りに思う作品をデザイン、作成、制作するために協力しています。',
        meet_the_team: 'チームに会う',
        team_description:
          '思いやり、独創性、細部へのこだわりは、私たちが設計、製造、販売するすべての製品の基盤です。',
        our_mission: '私たちの使命',
        mission_description:
          '私たちの使命は、ビジネスのトレンドと人々中心の文化と行動を重視する提案を重視する高品質のサービスと製品で、卓越したテクノロジーを広めることです。',
        our_activities: '私たちの活動',
        activities_description: '生計を立てるのに忙しくて、生計を立てることを忘れないでください。',
      },
      header_menu: {
        top: 'TOP',
        schedule: 'スケジュール',
        sales_management: '営業管理',
        order_management: '受注管理',
        deposit_management: '入金管理',
        performance_management: '業績管理',
        bulletin_board: '掲示板',
        to_do: 'やること',
      },
      footer: {
        company_profile: '会社概要',
        terms_of_service: '利用規約',
        privacy_policy: 'プライバシーポリシー',
        inquiry: 'お問い合わせ',
      },
      environment_setting: {
        environment_setting: '環境設定一覧',
        basic_config: '基本設定',
        office_settings: '事業所設定',
        profile: 'プロフィール',
        member_management: 'メンバー管理',
        data_settings: 'データ設定',
        goal_settings: '目標設定 ',
        partner_settings: '取引先設定 ',
        product_settings: '商材管理',
        group_settings: 'グループ設定',
        customization: 'カスタマイズ',
        admin_function: '管理者機能',
        sub_domain: 'サブドメイン管理 ',
        notice: 'お知らせ',
        faq: 'よくある質問',
        list_of_inquiries: 'お問い合わせ一覧',
        change_pic: '担当者一括変更',
        supplier_management: '仕入先一覧',
        meeting_room: '施設設定',
      },
      office_settings: {
        address: '住所',
        basic_information: '基本情報',
        corporate_number: '法人番号',
        estimate_invoice: '見積書 請求書',
        furigana_name: '正式名称フリガナ',
        invoice_compatible: 'インボイス対応',
        office_name: '事業所名',
        official_office_name: '事務所の正式名称',
        period: '期',
        phone_number: '電話番号',
        start_of_period: '期のスタート',
        round_up: '切り上げ',
        round_down: '切り捨て',
        round_off_to: '四捨五入',
        regular: '普通',
        current: '当座',
      },
      estimate_invoice: {
        amount_rounding_method: '金額端数処理方法',
        logo: 'ロゴ',
        company_seal: '社印',
        select_file: 'ファイルを選択',
        delete_registered_images: '登録されている画像の削除',
        number_rules: '採番のルール',
        numbering_estimate_rule: '見積りの採番のルール',
        invoice_numbering_rule: '請求書の採番のルール',
        preview: 'プレビュー',
        financial_institution1: '金融機関',
        bank_name: '取引先銀行',
        branch_name: '支店名',
        account_number: '口座番号',
        account_name: '口座名',
        financial_institution2: '金融機関２',
        example: '例：カ）アシリアスゲート',
        remarks_column: '備考欄',
        fixed_phrase: '定型文',
        transfer_bank: '振込先銀行',
        delete: '削除',
        register_multiple_financial: '複数の金融機関を登録',
        update_office: '更新する',
        seal_url: '社印のアップロードは必須です。',
        billing_date_incorrect_format: '見積日より後の日付を入力してください。',
        deposit_date_incorrect_format: '請求予定日より後の日付を入力してください。',
      },
      business_meeting: {
        page_title: '商談設定',
        client: '取引先',
        meeting_title: '商談名',
        date: '日付',
        time: '時間',
        contact_person: '担当者',
        session: '新規/継続',
        connection_method: '接続方法',
        remarks: 'メモ',
        proposal: '添付ファイル',
        select_a_file: 'ファイルを選択',
        other_attachments: 'その他の添付ファイル',
        created_estimates: '見積書',
        create_estimate: '見積書を作成',
        create_business_meeting: '商談を設定',
        invalid_files_length: 'アップロードできるのは最大で7ファイルです。',
        meeting_room: '施設',
        meeting_room_validation: '選択した施設は同時間帯に他の予定によってすでに使用されています。',
        estimate: {
          page_title: '見積件発注書の作成',
          copy_estimate: '既存の見積書を流用',
          client_name: '取引先名',
          post_code: '郵便番号',
          business_address: '取引先住所',
          subject: '件名',
          estimate_date: '見積り日',
          estimate_number: '見積書番号',
          scheduled_billing_date: '請求予定日',
          registration_number: '登録番号',
          deposit_date: '入金期日',
          bank_details: '振込先銀行',
          product_details: '明細',
          product_name: '摘要',
          product_quantity: '数量',
          product_unit_price: '定価',
          product_offered_unit_price: '提示金額',
          product_cost: '原価',
          product_amount: '小計',
          add_product_regular_row: '通常行を追加',
          add_product_text_row: 'テキスト行を追加',
          sub_total: '小計',
          consumption_tax: '消費税',
          quotation_amount: '見積金額',
          remarks: '備考',
          internal_remarks: '社内備考',
          create_estimate: '作成する',
          cancel_estimate: 'キャンセル',
          yen: '円',
          estimate_tab: '見積書',
          unregistered: '登録されている商品からお選び下さい。',
          copy: {
            title: '既存の見積書を流用',
            selection: '選択',
            estimation_date: '見積り日',
            subject: '見積件名',
            amount: '見積金額',
            cancel: 'キャンセル',
            copy_selected: '流用する',
          },
          consignment_label: '販売委託商品かどうか',
          consignment_item: '販売委託商品である',
        },
        invalid_before_start_time: '会議終了時間は開始時間より遅い時間を設定してください。',
        invalid_equal_meetng_time: '会議終了時間は会議開始時間と同じ時間では設定できません。',
        attendees: '参加者',
      },
      sales_management: {
        business_meeting: {
          tab_title: '商談一覧',
          status: 'ステータス',
          date: '商談日',
          time: '時間',
          client_name: '取引先名',
          meeting_title: '商談名',
          contact_person: '担当者',
          group_name: 'グループ名',
          in_charge: '担当分',
          session: '新規/継続',
          amount: '見積金額',
          product_type: '商品タイプ',
          actions: '操作',
          subject: '見積件名',
          connection_method: '接続方法',
          estimate_subject: '見積りの件名',
          update: {
            meeting_title: '商談名',
            date: '商談日',
            time: '時間',
            updated_at: '最終更新日',
            cancel: '閉じる',
            update: '保存する',
            lost: '失注変更',
            status: 'ステータス',
            session: '新規/継続',
            contact_person: '担当者',
            group_name: 'グループ',
            connection_method: '接続方法',
            client_name: '取引先名',
            select_a_file: 'ファイルを追加',
            proposal: '添付ファイル',
            other_attachments: 'その他の添付ファイル',
            estimates: '見積書',
            add_estimate: '見積書作成',
            invoice: '請求書',
            no_files: 'ファイルはまだありません',
            remarks: 'メモ',
            meeting_room: '施設',
            meeting_room_validation:
              '選択した施設は同時間帯に他の予定によってすでに使用されています。',
          },
          delete_confirmation: '選択した商談日を削除してもよろしいでしょうか？',
          delete_error: '商談日は削除できません。商談はすでに実施中です。',
          meeting_deleted: '商談日は削除されました。',
          date_to_incorrect_format: '前の日付より後の日付を入力して下さい。',
          all_statuses: 'すべて、',
          all_statuses_selected: 'すべて、他8件',
          search: '検索',
        },
        estimations: {
          status: 'ステータス',
          date: '見積日',
          title: '件名',
          person_in_charge: '担当者',
          group_name: 'グループ名',
          amount: '金額',
          name: '見積書',
          actions: '操作',
        },
        customer: {
          tab_title: '顧客一覧',
        },
        report: {
          tab_title: 'レポート一覧',
        },
      },
      order: {
        add_approver: '宛先を追加',
        address: '宛先',
        title: '見積兼発注書',
        account_name: '口座名',
        consignment_goods: '販売委託商品である',
        edit: '編集',
        estimate: '見積書',
        invoice: '請求書',
        preview: 'プレビュー',
        remand: '差戻し',
        estimated_date: '見積日',
        quotation_number: '見積書番号',
        scheduled_billing_date: '請求予定日',
        registration_number: '登録番号',
        subject: '件名',
        sub_total: '小計',
        consumption_tax: '消費税',
        estimation_amount: '見積金額',
        deposit_date: '入金期日',
        payee: '振込先',
        abstract: '摘要',
        quantity: '数量',
        unit_price: '単価',
        item_amount: '小計',
        internal_notes: '社内備考',
        breakdown: '内訳',
        excluding_tax: '10%対象（税抜）',
        consumption_tax_percent: '10%消費税',
        note: '下記の通りお見積り申し上げます',
        delete: '削除',
        download: 'ダウンロード',
        approve_internally: '社内承認する',
        remarks: '備考',
        approval_note1:
          '{{office_name}}から見積兼発注書の承認依頼となります。下記のご確認をお願い致します。',
        approval_note2: '承認、差戻しはページ下部のボタンからお進みください。',
        approve_confirmed: '承認',
        approve_confirmation_guest: '見積兼発注書を承認しますか？',
        approve_confirmation_staff: '見積書を承認しますか？',
        delete_quote: '本当にこの見積書を削除しますか？この操作は元に戻せません。',
        delete_success: '見積書が削除されました',
        comments: {
          customer_comments: '顧客差戻し',
          approver_comments: '社内差戻し',
        },
        internal_additional_recipient: '追加送付先（cc）',
        internal_address: '社内宛先',
        internal_approval_request: '社内承認依頼',
        internal_person_in_charge: '担当者（to）',
        internal_recipient_confirmation: '承認者に社内承認依頼を送信しました。',
        internal_recipient_confirmation2: 'に見積書・請求書を送信します。',
        approval_sent_message: 'に社内承認依頼を送信しました。',
        confirmation: '確認',
        rejection_comment: '差戻しの理由',
        comment: 'コメント',
        estimation_approved_success: '見積が承認されました。',
        rejection_notes_sent: '見積修正のためのコメントが追加されました',
        select_approver: '選択してください',
        send_email_recipient: '先方メール送付',
        scheduled_deposit_date: '入金予定日',
        product: {
          title: '摘要',
          cost: '原価',
          unit_price: '定価',
          offered_unit_price: '提示金額',
          quantity: '数量',
          amount: '小計',
        },
      },
      buttonStatus: {
        not_sent: '未送付',
        approver: '承認者',
      },
      invoice: {
        date: '請求日',
        status: 'ステータス',
        billing_date: '請求日',
        deposit_date: '入金日',
        subject: '件名',
        pic_name: '担当者',
        client_name: '取引先名',
        group_name: 'グループ名',
        amount: '金額',
        invoice: '請求書',
        paid_list_tab: '入金済',
        unpaid_list_tab: '未入金',
        cancel: '取消',
        cancel_confirm: {
          message_single: '請求書を未入金に移行しますか。',
          message_multiple: '請求書を未入金に移行しますか。選択された請求書を未入金に移行します。',
          success: '請求書を未入金に移行しました。',
          error: 'エラーが発生しました。',
        },
        note: '下記の通りご請求申し上げます',
        number: '請求書番号',
        registration_number: '登録番号',
        sub_total: '小計',
        consumption_tax: '消費税',
        consumption_and_tax_rate: '消費税（税率10%）',
        estimation_amount: '見積金額',
        invoice_amount: '請求額',
        payee: '振込先',
        abstract: '摘要',
        quantity: '数量',
        unit_price: '単価',
        item_amount: '小計',
        account_name: '口座名',
        breakdown: '内訳',
        excluding_tax: '10%対象（税抜）',
        consumption_tax_percent: '10%消費税',
        remarks: '備考',
        internal_notes: '社内備考',
        date_deposit: '入金期日',
        confirm_match: '一致を確認',
        unpaidInvoices: '未処理の請求書',
        paymentData: '入金データ',
        scheduled_deposit_date: '入金予定日',
        csv: {
          export: {
            supplier_list_title: '仕入先CSVダウンロード',
            supplier_details_title: '仕入先明細CSVダウンロード',
            supplier_list_default_filename: '仕入先CSV.csv',
            supplier_list_filename: '仕入先CSV_{{dates}}.csv',
            supplier_details_default_filename: '仕入先明細CSV_{{supplier}}.csv',
            supplier_details_filename: '仕入先明細CSV_{{supplier}}_{{dates}}.csv',
            list_button: '仕入先CSV',
            details_button: '仕入先明細CSV',
            period: '期間',
            supplier: '仕入先',
            product_name: '商材名',
            success: '',
            error: '',
            submit: 'ダウンロード',
          },
        },
        product: {
          title: '摘要',
          cost: '原価',
          unit_price: '単価',
          offered_unit_price: '提示金額',
          quantity: '数量',
          amount: '小計',
        },
      },
      unpaid_list: {
        unpaid_invoices: '未処理の請求書',
        payment: '入金データ',
        billing_date: '入金日',
        client_name: '振込人依頼名',
        amount: '金額',
        total_invoices: '請求書の合計金額',
        total_payments: '入金の合計金額',
        confirm_match: '一致を確認',
      },
      payments: {
        csvUpload: '銀行CSV取込み',
        csvUploadError: '銀行CSV取込みエラー',
        upload: 'アップロード',
        selectBank: '銀行を選択',
        fileUpload: 'CSVアップロード',
        selectFile: 'ファイルを選択',
        save: '取込む',
        check_csv_content:
          '空欄、日付形式の誤り、または特殊文字を含む振込人依頼者名があるかご確認の上、エラーを修正してください。',
      },
      calendar: {
        year: '年',
        month: '月',
        week: '週',
        day: '日',
        complete_list: '全表示',
        dayView: {
          connection: '接続方法',
          time: '時間',
          client_name: '取引先名',
          title: 'タイトル',
          title2: 'タイトル',
          meeting_title: '商談名',
          participants: '同席者',
          memo: 'メモ',
          others: 'その他',
          attendees: '同席者',
          other: 'その他',
          delete_schedule: '予定を削除',
        },
      },
      group_settings: {
        page_title: 'グループ設定',
        top_group: 'トップグループ作成',
        create: '作成',
        edit: '編集',
        add: '追加',
        update: '更新',
        no_settings: 'まだグループは作成されていません。',
        group_name: 'グループ名',
        delete_confirm_message: 'グループを削除しますか?',
        delete_success_message: 'グループを削除しました。',
      },
      goal_settings: {
        page_title: '目標設定',
        year: '年',
        month: '月',
        overall_target: '全体目標',
        group_targets: 'グループ目標',
        duplicate: '複製',
        create: '新規作成',
        fiscal_year: '{{year}}年度',
        no_goals: 'まだ目標が設定されていません。',
        cancel: 'キャンセル',
        submit: '保存',
        target: '目標',
        empty_group:
          'グループはまだ追加されていません。目標を設定するにはグループを追加してください。',
        add_success_message: '目標を作成しました。',
        update_success_message: '目標を更新しました。',
        edit: '編集',
        no: 'いいえ',
        yes: 'はい',
      },
      performance_management: {
        page_title: '業績管理',
        person_in_charge: '担当者',
        meeting_count: '商談件数',
        estimate_count: '見積件数',
        estimate_amount: '見積金額',
        actual_sales: '実績（売上）',
        actual_gross: '実績（粗利）',
        target_sales: '目標金額（売上）',
        target_gross: '目標金額（粗利）',
        achievement_rate: '達成率',
        group: 'グループ',
        period: '期間',
        search: '反映',
        sales_section_total: '合計',
        empty_performance: '該当するデータはありませんでした。',
      },
      meeting_room: {
        list: '施設設定',
        create: '施設作成',
        breadcrumbs: {
          home: 'ホーム ',
          environment_setting: '環境設定一覧',
          meeting_room: '施設設定',
          create: '施設作成',
        },
        form: {
          unique: 'この名称はすでに登録されています。',
          change_confirmation: '別の名称に変換してください。',
          minLength: '1~50文字以上である必要があります。',
          incorrect_format: 'の形式で入力して下さい。',
          already_registered: 'はすでに登録されています。 別の',
          already_exist1: 'はすでに登録されています。 別の',
          already_exist2: 'を入力してください。',
        },
        name: '名称',
        delete_confirmation: '削除してもよろしいでしょうか？',
        delete_success: '削除が完了しました。',
        room_in_use: 'すでに使用されている会議室を削除する。',
      },
      internal_meeting: {
        create_schedule: '予定を作成',
        schedule: '予定',
        cancel: 'キャンセル',
        register: '登録する',
        date: '日付',
        time: '時間',
        repeat: '繰り返し',
        title: 'タイトル',
        organizer: '作成者',
        meeting_attendees: '参加者',
        visibility: '公開設定',
        meeting_room: '施設',
        documents: '添付ファイル',
        select_a_file: 'ファイルを選択',
        internal_remarks: 'メモ',
        delete_schedule: '予定を削除',
        delete_confirmation1: 'この予定は繰り返しが設定されています',
        delete_confirmation2: '削除方法を選択してください',
        delete: '削除する',
        time_validation1: '終了日は開始日より後でなければなりません。',
        time_validation2: '会議終了時間は会議開始時間と同じ時間では設定できません。',
        meeting_room_validation: '選択した施設は同時間帯に他の予定によってすでに使用されています。',
        delete_success: '削除が完了しました。',
        delete_single: 'この予定のみを削除する。',
        add_participants: '参加者を追加',
        invalid_files_length: 'アップロードできるのは最大で7ファイルです。',
        no_options: '選択肢なし',
        scheduled: '予定あり',
        delete_one: 'この予定のみ削除',
        delete_series: '続く予定もすべて削除',
        maxLength: '1～50桁で必要があります。',
      },
    },
    menu: {
      home: '家',
      about: '約',
      inquiry: 'お問い合わせ',
      faq: 'よくあるご質問',
      dashboard: 'ダッシュボード',
      users: 'ユーザー',
      orders: '注文',
      reports: 'レポート',
      integrations: '統合',
      profile: 'プロフィール',
      login: 'ログイン',
      logout: 'ログアウト',
      terms: '利用規約',
      privacy_policy: 'プライバシーポリシー',
      documentation: 'ドキュメンテーション',
      api_reference: 'APIリファレンス',
      support: 'サポート',
      styleguide: 'スタイルガイド',
    },
    table: {
      no_data: 'データがありません',
      btn_renewal: '更新',
    },
    office: {
      labels: {
        home: 'ホーム ',
        preference: '環境設定一覧',
        sub_domain_management: 'サブドメイン管理',
        sub_domain_create: 'サブドメイン新規作成',
        button_create: '新規作成',
        stop: '停止',
        launch: '起動',
        confirm_change_title: '変更しますか？',
        no: 'いいえ',
        yes: 'はい',
        change_max_user_limit_success_message: '上限数を更新しました。',
        max_user_count_reduce_error:
          'この取引先はアカウント上限をすでに利用しているため、ユーザー数の上限を現在のユーザー数未満に減らすことはできません。',
        max_user_count_exceeded_error: 'この取引先はアカウント上限をすでに利用しているため。',
      },
      table: {
        labels: {
          sub_domain: 'サブドメイン',
          client_name: '取引先名',
          registration_date: '登録日',
          status: 'ステータス',
          status_activated: '起動',
          status_suspended: '停止',
          supension: '停止するとサービスが利用できなくなります。',
          do_you_want_to_stop: '停止しますか？',
          do_you_want_start: '起動しますか？',
          max_user_count: 'アカウント上限',
          updated_by: '最終更新',
          edit_max_user_count: '上限数変更',
          cancel_edit_max_user_count: 'キャンセル',
          change_max_user_count: '変更する',
        },
      },
      form: {
        once_entered: '一度、入力すると変更ができなくなります。入力例：ashiriasgate',
        sub_domain_name: 'サブドメイン名',
        office_set_up: '事業所設定',
        office_name: '事業所名',
        job_marketing_hokkaido: 'ジョブマーケティング北海道',
        use_for_display: 'システム内での表示に使用します。略称でも問題ありません',
        address: '住所',
        phone_number: '電話番号',
        official_name: '事務所の正式名称',
        use_official_doc: '正式な書類の際に使用します。正式名称で設定してください',
        formal_name: '正式名称フリガナ',
        full_name: '氏名',
        corporate_number: '法人番号',
        invoice_support: 'インボイス対応',
        invoice_business: '適格請求書発行事業者登録済',
        revenue_category: '目標設定',
        term: '期のスタート',
        user_preferences: 'ユーザー設定',
        display_name: '表示名',
        name: 'お名前',
        label_legal_name: '正式な氏名を記入してください',
        phonetic: 'ふりがな',
        legal_name: '正式な氏名のふりがなを記入して下さい。',
        bank_name: '銀行CSVデータでの社名表記と同一形式でお願いします。',
        user_it_for_login: 'ログインIDに使用します',
        user_id_for_login_password: 'ログインPWに使用します',
        select_a_file: 'ファイルを選択',
        delete_selected_image: '画像を削除',
        user_for_icon_image: 'システム内のアイコン画像として使用します',
        create_subdomain_label: 'サブドメイン新規作成',
        office_user_icon: 'アイコン',
        unique_subdomain: 'このサブドメインはすでに使用されています',
        unique_email: 'このメールアドレスはすでに使用されています',
        new_data_has_been_saved: '新規データが保存されました。',
        upload_image: ' 画像をアップロード',
        max_user_count: 'アカウント上限数',
      },
    },
    product: {
      labels: {
        amount_of_money: '定価',
        amount_of_money_header: '定価',
        merchandise_management: '商材管理',
        number_only: '数字のみ。例：30000',
        product_type_create: '商品タイプ作成',
        product_name_header: '商品名',
        product_type_header: '商品区分',
        supplier_header: '仕入先',
        cost_yen_header: '原価（￥）',
        cost_percentage_header: '原価（％）',
      },
      delete_confirmation: '削除してもよろしいでしょうか？',
      delete_success_message: '削除が完了しました。',
      breadcrumbs: {
        home: 'ホーム ',
        environment_setting: '環境設定一覧',
        product_type_settings: '商品タイプ設定',
        create_new_product: '商材新規作成',
        product_settings: '商材管理',
      },
      form: {
        product_type: '商品タイプ ',
        supplier: '仕入先 ',
        cost: '原価',
        cost_yen: '原価（￥）',
        cost_percentage: '原価（％）',
        submit_button: '作成する',
        already_registered: 'はすでに登録されています。 別の',
        product_type_still_in_use: '製品タイプはまだ使用中です。',
      },
    },
    client: {
      labels: {
        account_setting: '取引先設定',
        create_new_account: '取引先の新規作成',
        client_deleted: '顧客が削除されました',
      },
      table_header: {
        customer_number: '顧客番号',
        account_name: '取引先名',
        contact: '取引先担当者',
        phone_number: '取引先担当者',
        email_address: 'メールアドレス',
        address: '住所',
        department: '部署',
      },
      form: {
        company_name: '会社名',
        name_furigana: 'カタカナ(銀行口座表記名)',
        zip_code: '郵便番号',
        please_register_only: '請求先が基本設定と違う場合のみ、ご登録ください',
        billing_information: '請求先情報',
        please_include_full_name: '書類に使用します。正式名称を記載してください',
        title_of_honor: '敬称',
        use_it_for_documents: '書類に使用します',
        department: '部署',
        person_in_charge_corp: '自社担当者（責任者）',
        name_of_person_in_charge: '担当者名',
        quotation_invoice: '見積書、請求書送信に使用します',
        customer_number_not_exist: 'このお客様番号は存在しません',
        no_result_found: '検索結果が見つかりません',
        billing_information_is_not_setup: '請求先情報が設定されていません',
        number_format: '顧客番号の形式で入力して下さい。',
        department_helper_text: '書類に使用します',
        email_helper_text: '見積書、請求書送信に使用します',
        remarks: '備考',
      },
      view: {
        details: '顧客詳細',
        client_contract: '取引先担当者',
        edit: '編集',
        address: '住所',
        phone_number: 'TEL',
        customer_number: '顧客番号',
        customer_responsible_person: '自社担当',
        pic_name: '氏名',
        email: 'メール',
        department: '部署',
        billing_address: '請求先',
        tel: 'Tel',
        mail: 'Mail',
        tab: {
          meetings: '商談履歴',
          estimate: '見積書',
          invoice: '請求書',
        },
        remarks: '備考',
      },
      csv: {
        title: '顧客CSV取込み',
        save: '取込む',
        success: '{{total}}行中{{valid}}行をインポートしました。',
        error: {
          empty_field: '{{field}}は必須項目です。',
          duplicate_field: '{{field}}は重複しています。',
          exceeded_file_size: '正しいCSVのフォーマットでご登録ください。',
          invalid_file: 'CSV (最大 10MB) を添付してください。',
          incorrect_format: 'Incorrect field {{field}}。',
          check_content:
            '空欄、重複、誤った形式（郵便番号、電話番号、電子メールなど）がないか確認し、エラーを修正してください。',
        },
        filename: '顧客_サンプルCSV.csv',
      },
    },
    calendar: {
      display_member: '表示メンバー',
      member_selection: 'メンバー選択',
    },
    breadcrumbs: {
      home: 'ホーム ',
      environment_setting: '環境設定一覧',
      member_management: 'メンバー管理',
      office_settings: '事業所設定',
      profile_setting: 'プロフィール設定',
      sales_management: '営業管理',
      business_meeting: '商談設定',
      business_meeting_list: '商談一覧',
      quotation_list: '見積書一覧',
      create_quotation: '見積書作成',
      quotation_comment: '見積書{{estimate_number}}',
      member_details: 'メンバー詳細',
      create_member: 'メンバー作成',
      update_member: 'メンバー編集',
      customer_list: '顧客一覧',
      business_partner_details: '顧客詳細',
      deposit_management: '入金済',
      unpaid_invoice: '未入金',
      invoice_preview: '請求書作成',
      group_settings: 'グループ設定',
      goal_settings: '目標設定',
      performance_management: '業績管理',
    },
    help_guide: {
      information_notes: '入力したものが見積書、請求書の備考欄に自動で挿入されます',
      use_for_display: 'システム内での表示に使用します。略称でも問題ありません',
      use_for_reports_and_goals: 'レポートや目標に使用します',
      use_official_doc: '正式な書類の際に使用します。正式名称で設定してください',
      registration_number_for_invoice: '請求書で登録番号が自動で記載されます',
      enter_quotation_number_and_invoice: '見積書番号、請求書番号を自動記載する際に使用します',
      invoice_transfer_destination: '請求書で振込先を自動記載する際に使用します',
      inconvenience_note: '※恐れ入りますが振込手数料は御社ご負担でお願い致します。',
      phone_number: '半角で入力して下さい。',
      consignment_goods: '※チェックをつけると、顧客へ見積兼発注書・請求書の発送は行いません',
    },
    browser_title: {
      default: 'Ashirias Gate',
      login: 'ログイン',
      forgot_password: 'パスワード変更',
      reset_password: 'パスワード再設定',
      top: 'TOP',
      schedule: 'カレンダー',
      contact_us: 'お問い合わせ',
      meeting_room: '施設設定',
      meeting_create: '施設作成',
      sales: {
        list: '営業管理（商談一覧）',
      },
      order: {
        list: '受注管理（見積り一覧）',
        view: '請求書プレビュー',
        comment: '見積書差戻し履歴',
        guest_view: '外部承認',
        create: '見積書作成',
      },
      deposit: {
        list: '入金管理（入金済）',
      },
      performance: {
        list: '業績管理',
      },
      bulletin_board: {
        list: '掲示板一覧',
      },
      todo: {
        list: 'やること一覧',
      },
      group: {
        settings: 'グループ設定',
      },
      goal: {
        settings: '目標設定',
      },
      office: {
        list: 'サブドメイン管理',
        create: 'サブドメイン新規作成',
        settings: '事業所設定',
      },
      product_type: {
        list: '商品タイプ設定',
        create: '商品タイプ作成',
      },
      customer: {
        list: '営業管理（顧客一覧）',
        create: '取引先新規作成',
        edit: '取引先新規作成',
        view: '営業管理（顧客詳細）',
      },
      product: {
        list: '商材管理',
        create: '商材新規作成',
        edit: '商材新規作成',
      },
      environment_setting: {
        list: '環境設定一覧',
      },
      users: {
        list: 'メンバー管理',
        create: 'メンバー詳細',
        edit: 'メンバー詳細',
      },
      business_meeting: {
        create: '商談設定',
      },
      supplier: {
        list: '仕入先一覧',
        create: '仕入先登録',
      },
      estimation: {
        view: '見積書',
      },
      invoice: {
        view: '請求書',
      },
    },
    change_pic: {
      labels: {
        prev_PIC: '変更前担当者',
        next_PIC: '変更後担当者',
        change_button: '変更する',
      },
      table_header: {
        customer_number: '顧客番号',
        customer_name: '取引先名',
        contact_person: '取引先担当者',
        department: '部署',
      },
      modal: {
        title: '担当者の変更確認',
        cancel_buttpn: 'キャンセル',
        confirm_button: '確定',
        confirmation_message: '以下の担当者変更を実行します。本当に変更してもよろしいですか？',
        impact_warning_message:
          'この変更は全ての関連データに影響しますので、慎重に確認してください。',
      },
      error: {
        select_at_least_one: '少なくとも1つを選択してください。',
      },
      success: {
        message: '顧客を新しい担当に更新しました。',
      },
    },
    supplier: {
      supplier_management: '仕入先一覧',
      upplier_list: '仕入先一覧',
      create_new_supplier: '仕入先登録',
      breadcrumbs: {
        home: 'ホーム ',
        environment_setting: '環境設定一覧',
        supplier_list: '仕入先一覧',
        create_new_supplier: '仕入先登録',
      },
      form: {
        name: '仕入先',
        submit_button: '作成する',
        already_registered: 'はすでに登録されています。 別の',
        still_in_use: 'サプライヤーはまだ使用中です。',
      },
      delete_confirmation: '削除してもよろしいでしょうか？',
      delete_success_message: '削除が完了しました。',
    },
  },
};

export default ja;
