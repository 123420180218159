import { ROLES } from 'config/consts/roles';
import routes from 'router/routes';
import { clearTokenCookie } from './cookie';
import { redirectAsSubdomain } from './subdomain';

const LOGIN_ROUTE = '/login';
const ADMIN_ROUTE = '/admin';
// const SALES_MANAGEMENT_ROUTE = '/sales-management';  //temporary comment out, due to top page
const SETTINGS_ROUTE = '/environment-settings';
const TOP_ROUTE = '/top';

/**
 * Determines if the current page is the root ("/") or a login path.
 *
 * @returns {boolean} True if the current path is root or starts with the login route; otherwise, false.
 */
const isRootOrLoginPath = () => {
  const path = window.location.pathname;
  return path === '/' || path.startsWith(LOGIN_ROUTE);
};

/**
 * Retrieves the complete path to which the user should be redirected.
 *
 * This looks for a `redirect_to` query parameter in the current URL,
 * and if found, appends the current URL's hash (if any) to it.
 *
 * @returns {string|null} The complete redirection path including the hash,
 * or `null` if the `redirect_to` query parameter is not present.
 */
export const getRedirectToCompletePath = () => {
  const query = new URLSearchParams(location.search);
  const redirectTo = query.get('redirect_to');
  if (redirectTo) {
    return `${redirectTo}${window.location.hash}`;
  }
  return null;
};

/**
 * Redirects the user to a specific admin route based on their status and the current path.
 *
 * If the user is unauthenticated or already on an admin route, no action is taken.
 * If the user accesses the root or login path, they are redirected to a specified path or the dashboard within the admin route.
 * For other paths, if the path requires authentication and the user is authenticated, they are redirected to the corresponding admin route.
 *
 * @param {Object} user The user object containing the user's information.
 * @returns {boolean} True if a redirection occurs, false otherwise.
 */
const redirectAsHkAdmin = (user) => {
  const host = window.location.host;
  const path = window.location.pathname;

  // Redirect to 403 if the current host is not the expected domain host
  if (process.env.REACT_APP_APP_DOMAIN !== host) {
    window.location = '/403';
    return true;
  }

  // If the user object is not provided or the current path starts with the admin route, do nothing.
  if (!user || path.startsWith(ADMIN_ROUTE)) return false;

  // Redirect to a specified path within the admin route or to the dashboard.
  if (isRootOrLoginPath()) {
    const redirectTo = getRedirectToCompletePath() ?? SETTINGS_ROUTE; //temporary redirection due to MVP feature inclusion only
    window.location = `${ADMIN_ROUTE}${redirectTo}`;
    return true;
  }

  const matchingRoute = routes.find((route) => route.path === path);
  // If the route does not require authentication, do nothing.
  if (!matchingRoute.auth) return false;

  window.location = `${ADMIN_ROUTE}${path}`;
  return true;
};

/**
 * Redirects the user to the main app domain if they are not already there.
 *
 * @returns {boolean} True if a redirection occurs, false otherwise.
 */
const redirectAsHkOffice = () => {
  const host = window.location.host;

  // Redirect to 403 if the current host is not the expected domain host
  if (process.env.REACT_APP_APP_DOMAIN !== host) {
    window.location = '/403';
    return true;
  }

  if (isRootOrLoginPath()) {
    window.location = getRedirectToCompletePath() ?? TOP_ROUTE;
    return true;
  }

  return false;
};

/**
 * Redirects the user based on their role and specific conditions.
 *
 * If the user is not on the application's main domain, they are redirected to it.
 * If the user is on the root or login path, they maybe redirected to a specific path based on a query parameter or to a default dashboard route.
 *
 * @param {Object} user The user object containing the user's role and other information.
 * @returns {Promise<boolean>} A promise that resolves to true if a redirection occurs, false otherwise.
 */
export const redirectByUserType = async (user) => {
  if (!user) return false;

  if (!isRootOrLoginPath()) {
    const matchingRoute = routes.find((route) => route.path === window.location.pathname);
    // If the route does not require authentication, do nothing.
    if (matchingRoute && !matchingRoute.auth) return false;
  }

  const role = user.role[0];

  if (role === ROLES.hokkaidoAdmin) return redirectAsHkAdmin(user);

  if (role === ROLES.administrator && user.isHkOffice) return redirectAsHkOffice();

  if (role === ROLES.administrator || role === ROLES.employee || role === ROLES.approver) {
    const subdomain = user?.offices[0]?.sub_domain ?? undefined;
    return await redirectAsSubdomain(subdomain);
  }

  return false;
};

/**
 * Constructs the default domain URL.
 *
 * @returns {string} The default domain URL.
 */
export const defaultDomain = () =>
  `${window.location.protocol}//${process.env.REACT_APP_APP_DOMAIN}`;

/**
 * Constructs a link path based on the user's role.
 *
 * @param {Object} user The user object containing the user's role.
 * @param {string} path The path to potentially modify based on the user's role.
 * @returns {string} The modified path if the user is a Hokkaido Admin, otherwise the original path.
 */
export const getLinkPathByUserType = (user, path) => {
  const role = user?.role[0] ?? '';
  return role === ROLES.hokkaidoAdmin ? `${ADMIN_ROUTE}${path}` : path;
};

/**
 * Redirects the user to the default domain if unauthorized and on the root or login path.
 *
 * @returns {boolean} True if a redirection occurs, false otherwise.
 */
export const redirectIfUnauthorized = () => {
  const host = window.location.host;
  const path = window.location.pathname;

  if (isRootOrLoginPath() && process.env.REACT_APP_APP_DOMAIN !== host) {
    clearTokenCookie();
    window.location = `${defaultDomain()}${path}`;
    return true;
  }
  return false;
};

export const removeQueryParam = (param) => {
  const currentUrl = new URL(window.location);
  const searchParams = currentUrl.searchParams;
  searchParams.delete(param);

  currentUrl.search = searchParams.toString();

  // Use history.replaceState to update the URL in the browser address bar without reloading the page
  window.history.replaceState({}, '', currentUrl);
};
