import axios from 'axios';
import { defaultDomain } from './appRedirect';
import { clearTokenCookie, getCookie, setTokenCookie } from './cookie';

const baseUrl = window.location.origin;
const apiUrl = `${baseUrl}/${process.env.REACT_APP_API_PATH}`;
const api = axios.create({ baseURL: apiUrl });

// set access token
const access_token = getCookie('access_token');
if (access_token) api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

const handleResponse = (response) => response;
const handleError = (error) => {
  const code = error.response && parseInt(error.response.status);
  const originalRequest = error.config;
  const data = originalRequest && originalRequest.data ? originalRequest.data : null;
  let params = data && data instanceof FormData ? data : JSON.parse(data);

  // handle failed attempt on refreshing the token
  if (
    code === 401 &&
    originalRequest.url === '/oauth/token' &&
    params.grant_type === 'refresh_token'
  ) {
    // remove from store
    clearTokenCookie();
    const redirectTo = `${location.pathname}${window.location.hash}`;
    window.location = `${defaultDomain()}/login?redirect_to=${redirectTo}`;
  }

  const refresh_token = getCookie('refresh_token');

  if (code === 403 && window.location.pathname !== '/403') {
    window.location = '/403';
    return;
  }

  if (code === 401 && originalRequest.url !== '/oauth/token' && refresh_token) {
    return new Promise((resolve, reject) => {
      api
        .post('/oauth/token', {
          grant_type: 'refresh_token',
          refresh_token,
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          scope: '',
        })
        .then(({ data }) => {
          const { access_token, refresh_token, expires_in } = data;

          // update localstorage
          setTokenCookie(access_token, refresh_token, expires_in);

          // update bearer token in current axios request
          originalRequest.headers.Authorization = `Bearer ${access_token}`;

          resolve(data);
        })
        .catch((e) => reject(e));
    })
      .then(() => api(originalRequest)) // proceed to original request
      .catch(() => (window.location = '/login')); // force redirect to login page
  }

  return Promise.reject(error);
};

// Add a response error interceptor
api.interceptors.response.use(handleResponse, handleError);

export default api;
