import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loader from 'components/atoms/Loader';
import { ROLES } from 'config/consts/roles';
import api from 'utils/api';
import { redirectByUserType } from 'utils/appRedirect';
import BrowserTitle from './browser-title';
import routes from './routes';

function Router() {
  const DashboardLayout = lazy(() => import('templates/Authenticated'));
  const GuestTemplate = lazy(() => import('templates/Guest'));
  const [user, setUser] = useState();
  const [checking, setChecking] = useState(true);

  /**
   * Fetches the current user's profile information from the API and updates the user state.
   * It also handles redirection based on the user's role and manages a loading state.
   */
  const fetchCurrentUser = async () => {
    try {
      setChecking(true);
      const { data } = await api.get('/profile');
      const user = data.data;
      setUser(user);

      // Attempt to redirect based on the user's type. If redirection is not needed, disable the loading state.
      if (!(await redirectByUserType(user))) setChecking(false);
    } catch (_) {
      setChecking(false);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  if (checking) {
    return (
      <Suspense fallback={<Loader />}>
        <BrowserTitle />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Loader />}>
      <BrowserTitle />
      <Routes>
        {routes.map((route, i) => {
          const Page = lazy(() => import(`../${route.component}`));
          const roles = route.roles || [];

          const userRole = user?.role[0];
          let path = route.path;
          if (userRole === ROLES.hokkaidoAdmin && route.auth) {
            // Modifies the 'path' to point to an admin-specific path.
            // The user should be navigated to an admin version of the route.
            path = `/admin${path}`;
          }

          const layout = route.auth ? (
            <DashboardLayout roles={roles} auth={route.auth} path={path} />
          ) : (
            <GuestTemplate navbar={route.navbar} />
          );
          return (
            <Route key={i} element={layout}>
              <Route exact path={path} element={<Page />} />
            </Route>
          );
        })}
      </Routes>
    </Suspense>
  );
}

export default Router;
