/**
 * Sets the access token and refresh token as cookies on the application's domain.
 * If the tokens are not provided as arguments, the function attempts to retrieve them
 * from localStorage using the keys 'access_token' and 'refresh_token'.
 *
 * The cookies are set with a domain obtained from the environment variable REACT_APP_APP_DOMAIN,
 * ensuring they are available across the entire domain. The path is set to '/', making the cookies
 * accessible to all paths on the domain.
 *
 * @param {string} [accessToken] - The access token to be set as a cookie.
 * @param {string} [refreshToken] - The refresh token to be set as a cookie.
 */
export const setTokenCookie = (accessToken, refreshToken, expiresIn) => {
  if (!accessToken || !refreshToken) return;

  const appDomain = process.env.REACT_APP_APP_DOMAIN;
  const cookieFlags = process.env.REACT_APP_COOKIE_FLAGS ?? '';
  const data = `domain=.${appDomain};path=/;${cookieFlags}`;
  document.cookie = `access_token=${accessToken};${data}`;
  document.cookie = `refresh_token=${refreshToken};${data}`;

  if (expiresIn) {
    document.cookie = `expires_in=${expiresIn};${data}`;
  }
};

/**
 * Clears a specified cookie by setting its expiration date to a past date, effectively
 * deleting it from the browser.
 *
 * This constructs a cookie string using the provided
 * cookie name and an empty value, along with the domain obtained from an environment variable
 * and a path. The expiration date is set to January 1, 1970, to ensure the cookie is expired.
 *
 * @param {string} name The name of the cookie to be cleared.
 * @param {string} value The value of the cookie, which will be cleared (typically an empty string).
 */
export const clearTokenCookie = () => {
  const appDomain = process.env.REACT_APP_APP_DOMAIN;
  const expires = 'Expires=Thu, 01 Jan 1970 00:00:00 GMT;';
  document.cookie = `access_token='';domain=.${appDomain};path=/;${expires}`;
  document.cookie = `refresh_token='';domain=.${appDomain};path=/;${expires}`;
  document.cookie = `expires_in='';domain=.${appDomain};path=/;${expires}`;
};

/**
 * Retrieves the value of a specified cookie from the document's cookies. If the cookie exists,
 * its value is decoded and returned. If the cookie does not exist, or if there are no cookies,
 * the function returns null.
 *
 * This parses the document's cookie string, splitting it into
 * individual cookies, and then searches for the specified cookie by name.
 *
 * @param {string} name The name of the cookie whose value is to be retrieved.
 * @returns {string|null} The decoded value of the specified cookie if it exists, otherwise null.
 */
export const getCookie = (name) => {
  if (!document.cookie || document.cookie === '') return null;

  let cookieValue = null;
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.substring(0, name.length + 1) === name + '=') {
      cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
      break;
    }
  }

  return cookieValue;
};
