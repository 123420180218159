// metrics in "rem" not "px"
const pxToRem = (val) => `${val / 16}rem`;

/**
 * MUI Typography
 * @see https://mui.com/material-ui/customization/typography/
 */
const typography = {
  fontFamily: 'Noto Sans JP',
  fontStyle: 'normal',
  fontSize: 18,
  lineHeight: 'normal',
  h1: {
    fontSize: pxToRem(48),
    fontWeight: 700,
  },
  h2: {
    fontSize: pxToRem(42),
    fontWeight: 700,
  },
  h3: {
    fontSize: pxToRem(36),
    fontWeight: 700,
  },
  h4: {
    fontSize: pxToRem(30),
    fontWeight: 700,
  },
  h5: {
    fontSize: pxToRem(24),
    fontWeight: 700,
  },
  h6: {
    fontSize: pxToRem(18),
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: pxToRem(14),
    fontWeight: 300,
  },
  subtitle2: {
    fontSize: pxToRem(12),
    fontWeight: 300,
  },
  body1: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  body2: {
    fontSize: pxToRem(12),
    fontWeight: 400,
  },
  button: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  caption: {
    fontSize: pxToRem(12),
    fontWeight: 300,
  },
  overline: {
    fontSize: pxToRem(10),
    fontWeight: 300,
  },
  customFormLabel: {
    color: '#333',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  helpGuide: {
    color: '#828282',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  toolbarButtonText: {
    fontSize: '10px',
    fontWeight: 400,
    color: '#fff',
  },
  calendarUsers: {
    maxWidth: '927px !important',
    wordWrap: 'break-word',
  },
  normal1: {
    color: '#00000 !important',
    fontWeight: 400,
    fontSize: 14,
  },
};

export default typography;
