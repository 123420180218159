const customerPath = {
  customerCreate: '/customer/create',
  customerEdit: '/customer/edit/:id',
  customerView: '/customer/view/:id',
};

const productPath = {
  product: '/product',
  productCreate: '/product/create',
  productEdit: '/product/edit/:id',
};

const environmentSettingsPath = {
  environmentSettings: '/environment-settings/',
  environmentSettingsOffice: '/environment-settings/:id',
};

const usersPath = {
  users: '/users',
  addUsers: '/add-users',
  updateUsers: '/users/:id',
};

const officePath = {
  office: '/office',
  officeCreate: '/office/create',
  officeSettings: '/office-settings',
};

const productTypePath = {
  productTypeCreate: '/product-type/create',
  productType: '/product-type',
};

const orderPath = {
  order: '/order',
  viewOrder: '/estimations/:id',
  approveEstimate: '/estimations/approve/:id',
  guestViewOrder: 'guest/estimations/approve/:id',
  createOrder: '/estimations/create/:meetingId',
};

const authPath = {
  login: '/login',
  signup: '/signup',
  forgotPassword: '/forgot-password',
  resetPassword: '/password/reset',
  activate: '/activate',
};

const businessMeetingPath = {
  addBusinessMeeting: '/business-meeting/create',
};

const topPath = {
  topView: '/top',
};

const schedulePath = {
  calendarMonthView: '/schedule',
};

const supplierPath = {
  supplierCreate: '/supplier/create',
  supplier: '/supplier',
};

const meetingRoomPath = {
  meetingRoomList: '/meeting-room',
  meetingRoomCreate: '/meeting-room/create',
};

export const ROUTE_PATH = {
  ...officePath,
  ...productTypePath,
  ...customerPath,
  ...productPath,
  ...authPath,
  ...environmentSettingsPath,
  ...usersPath,
  ...businessMeetingPath,
  ...orderPath,
  ...topPath,
  ...schedulePath,
  ...supplierPath,
  ...meetingRoomPath,

  notice: '/notice',
  listOfInquiries: '/list-of-inquiries',
  goalSettings: '/goal-settings',
  customization: '/customization',
  groupSettings: '/group-settings',
  changePIC: '/change-pic',

  dashboard: '/dashboard',

  about: '/about',
  profile: '/profile',

  terms: '/terms',
  integrations: '/integrations',
  faq: '/faq',
  inquiry: '/inquiry',

  top: '/top',
  schedule: '/schedule',
  salesManagement: '/sales-management',
  orderManagement: '/order-management',
  depositManagement: '/deposit-management',
  performanceManagement: '/performance-management',
  bulletinBoard: '/bulletin-board',
  todo: '/todo',
  help: '/help',

  contactUs: '/contact-us',
  aboutUs: '/about-us',
  termsOfUse: '/terms-of-use',
  privacyPolicy: '/privacy-policy',
};
