export const orange = {
  50: '#FCECD5',
  60: '#EED0A6',
};

export const buttonOrange = {
  50: '#EF9412',
};

export const tableOrange = {
  50: '#FFFAF3',
  60: '#FCECD5',
};

export const tableGrey = {
  50: '#ECECEC',
};

export const blue = {
  50: '058ed9',
};
