import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ROUTE_PATH } from './route-path';

function BrowserTitle() {
  const location = useLocation();
  const currentRoute = location.pathname;

  const { t } = useTranslation();
  const translate = (key) => t(`browser_title.${key}`);

  const salesRouteTranslations = {
    [ROUTE_PATH.salesManagement]: 'sales.list',
  };

  const orderRouteTranslations = {
    [ROUTE_PATH.orderManagement]: 'order.list',
  };

  const depositRouteTranslations = {
    [ROUTE_PATH.depositManagement]: 'deposit.list',
  };

  const performanceRouteTranslations = {
    [ROUTE_PATH.performanceManagement]: 'performance.list',
  };

  const authRouteTranslations = {
    [ROUTE_PATH.login]: 'login',
    [ROUTE_PATH.forgotPassword]: 'forgot_password',
    [ROUTE_PATH.resetPassword]: 'reset_password',
  };

  const bulletinBoardRouteTranslations = {
    [ROUTE_PATH.bulletinBoard]: 'bulletin_board.list',
  };

  const todoRouteTranslations = {
    [ROUTE_PATH.todo]: 'todo.list',
  };

  const officeRouteTranslations = {
    [ROUTE_PATH.office]: 'office.list',
    [ROUTE_PATH.officeCreate]: 'office.create',
    [ROUTE_PATH.officeSettings]: 'office.settings',
  };

  const productRouteTranslations = {
    [ROUTE_PATH.product]: 'product.list',
    [ROUTE_PATH.productCreate]: 'product.create',
  };

  const productTypeRouteTranslations = {
    [ROUTE_PATH.productType]: 'product_type.list',
    [ROUTE_PATH.productTypeCreate]: 'product_type.create',
  };

  const customerRouteTranslations = {
    [ROUTE_PATH.customer]: 'customer.list',
    [ROUTE_PATH.customerCreate]: 'customer.create',
  };

  const userRouteTranslations = {
    [ROUTE_PATH.users]: 'users.list',
    [ROUTE_PATH.addUsers]: 'users.create',
  };

  const businessMeetingRouteTranslations = {
    [ROUTE_PATH.addBusinessMeeting]: 'business_meeting.create',
  };

  const groupSettingRouteTranslations = {
    [ROUTE_PATH.groupSettings]: 'group.settings',
  };

  const goalSettingRouteTranslations = {
    [ROUTE_PATH.goalSettings]: 'goal.settings',
  };

  const supplierRouteTranslations = {
    [ROUTE_PATH.supplier]: 'supplier.list',
    [ROUTE_PATH.supplierCreate]: 'supplier.create',
  };

  const meetingRoomRouteTranslations = {
    [ROUTE_PATH.meetingRoomList]: 'meeting_room',
    [ROUTE_PATH.meetingRoomCreate]: 'meeting_create',
  };

  const otherRouteTranslations = {
    [ROUTE_PATH.top]: 'top',
    [ROUTE_PATH.schedule]: 'schedule',
    [ROUTE_PATH.contactUs]: 'contact-us',
  };

  /**
   * Contains route-to-translation key mappings.
   * Note that the translation key defaults to t('browser_title.{translation_key}').
   *
   * [route]:'translaction_key'
   *
   * @type {Object.<string, string>}
   */
  const routeTranslations = {
    // Add route translations here by module
    ...authRouteTranslations,
    ...salesRouteTranslations,
    ...orderRouteTranslations,
    ...depositRouteTranslations,
    ...performanceRouteTranslations,
    ...bulletinBoardRouteTranslations,
    ...todoRouteTranslations,
    ...officeRouteTranslations,
    ...productRouteTranslations,
    ...productTypeRouteTranslations,
    ...customerRouteTranslations,
    ...userRouteTranslations,
    ...businessMeetingRouteTranslations,
    ...groupSettingRouteTranslations,
    ...goalSettingRouteTranslations,
    ...supplierRouteTranslations,
    ...otherRouteTranslations,
    ...meetingRoomRouteTranslations,
    default: 'default',
  };

  /**
   * An array of objects each representing a dynamic route pattern and its
   * associated translation key. This allows for handling of dynamic segments
   * in routes (e.g., IDs in edit pages) that cannot be matched directly using
   * static path strings. Each object contains a `pattern` that is a substring
   * to match in the current route and a `key` that corresponds to the translation
   * key for that route pattern.
   *
   * [dynamic_route_segment]:'translaction_key'
   *
   * @example dynamic route
   * /customer/edit/{id}
   * /product/edit/{id}
   *
   * @type {Array.<{pattern: string, key: string}>}
   */
  const dynamicRouteTranslations = [
    // add dynamic route segments
    { pattern: '/sales-management#customers', translationKey: 'customer.list' },
    { pattern: '/product/edit/', translationKey: 'product.edit' },
    { pattern: '/environment-settings', translationKey: 'environment_setting.list' },
    { pattern: '/users/', translationKey: 'users.edit' },
    { pattern: '/guest/estimations/', translationKey: 'order.guest_view' },
    { pattern: '/estimations/create', translationKey: 'order.create' },
    { pattern: '/estimations/', translationKey: 'estimation.view' },
  ];

  const getTitle = () => {
    // Check for static routes first
    if (typeof routeTranslations[currentRoute] !== 'undefined') {
      return translate(routeTranslations[currentRoute]);
    }

    // Handle dynamic routes
    const dynamicRoute = dynamicRouteTranslations.find((route) =>
      currentRoute.includes(route.pattern)
    );
    if (typeof dynamicRoute !== 'undefined') {
      return translate(dynamicRoute.translationKey);
    }

    // Fallback to default if no match is found
    return translate(routeTranslations['default']);
  };

  useEffect(() => {
    document.title = getTitle();
  }, [currentRoute]);

  return null;
}

export const changeBrowserTitle = (title) => {
  document.title = title;
};

export default BrowserTitle;
